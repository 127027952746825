.login-form-cover{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.login-form-cover>div:first-child{
    height: 100vh;
}
.login-form-cover>div:last-child{
    background: var(--main-color);

}


.login-form{
    width: 950px;
    background-color: white;
    border-radius: 3px;
    overflow: hidden;
    max-width: 100%;
}


@media only screen and (max-width: 976px) {
    .login-form{
        width: 93%;
    }
    .login-form img{max-width: 400px;}
}
@media only screen and (max-width: 850px) {
    .login-form .light-grey-bg .light-grey-text{
        display: none;
    }
}

.fs-13{
    font-size: 13px !important;
}



